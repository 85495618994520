import React, { useEffect } from "react";
import bgimage from "../../Assets/images/bgimage1.png";
import image1 from "../../Assets/images/image1.png";
import phonehand from "../../Assets/images/phonehand.png";
import imglogo from "../../Assets/images/imglogo.png";
import rightarrow from "../../Assets/images/arrow-right.png";
import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import downloadapp1 from "../../Assets/images/downloadapp1.png";
import downloadapp2 from "../../Assets/images/downloadapp2.png";
import gplayimg from "../../Assets/images/gplayimg.png";
import appstoreimg from "../../Assets/images/appstoreimg.png";
import card1 from "../../Assets/images/card1.png";
import card2 from "../../Assets/images/card2.png";
import card3 from "../../Assets/images/card3.png";
import location from "../../Assets/images/location.png";
import call from "../../Assets/images/call.png";
import sms from "../../Assets/images/sms.png";
import work1 from "../../Assets/images/work-1.png";
import work2 from "../../Assets/images/work-2.png";
import work3 from "../../Assets/images/work-3.png";
import work4 from "../../Assets/images/work-4.png";
import work5 from "../../Assets/images/work-5.png";
import work6 from "../../Assets/images/work-6.png";
import { useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Marquee from "react-fast-marquee";
import "../../App.css";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "react-phone-input-2/lib/style.css";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/All.css";

function ScrollToSection() {
  const { hash } = useLocation();

  React.useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return null;
}

const validationSchema = Yup.object({
  fname: Yup.string().required("Full Name is required"),
  phoneno: Yup.string().required("Phone number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  message: Yup.string().required("Message is required"),
});

function Homepage() {
  const linkRef = useRef(null);
  const [Open, setOpen] = useState(false);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [phoneData, setPhoneData] = useState({
    number: "",
    countryCode: "in",
    dialCode: "91",
    inputValue: "",
    isValid: false,
  });
  const handlePhoneChange = (phone, countryCode) => {
    const userInput =
      countryCode && countryCode.country && countryCode.country.dialCode
        ? phone.replace(`+${countryCode.country.dialCode}`, "").trim()
        : phone.trim();

    const parsedPhone = parsePhoneNumberFromString(phone);

    const updatedPhoneData = {
      number: phone,
      countryCode:
        countryCode && countryCode.country ? countryCode.country.iso2 : "in",
      dialCode:
        countryCode && countryCode.country
          ? `+${countryCode.country.dialCode}`
          : "+91",
      inputValue: userInput,
      isValid: parsedPhone ? parsedPhone.isValid() : false,
    };
    setPhoneData(updatedPhoneData);
    formik.setFieldValue("phoneno", updatedPhoneData.number);
  };

  const formik = useFormik({
    initialValues: {
      fname: "",
      phoneno: "",
      email: "",
      message: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log("values>>>>>>>>", values);
      resetForm();
      setPhoneData(" ");
    },
  });

  return (
    <>
      <ScrollToSection />
      <section>
        <div className=" lg:h-[70px] sm:h-[60px] h-[50px] flex items-center w-full">
          <Marquee>
            <div className="flex items-center lg:gap-x-11 gap-x-8">
              <div className=" flex items-center gap-x-4 ml-8">
                <img
                  src={imglogo}
                  alt="imglogo"
                  className=" xl:h-10 sm:h-8 h-6"
                />
                <span className="fonts-medium xl:text-[28px] lg:text-2xl sm:text-xl text-base  xl:leading-[52px] leading-10">
                  Coming Soon
                </span>
              </div>
              <div className=" flex items-center gap-x-4">
                <img
                  src={imglogo}
                  alt="imglogo"
                  className=" xl:h-10 sm:h-8 h-6"
                />
                <span className="fonts-medium xl:text-[28px] lg:text-2xl sm:text-xl text-base xl:leading-[52px] leading-10">
                  Coming Soon
                </span>
              </div>
              <div className=" flex items-center gap-x-4">
                <img
                  src={imglogo}
                  alt="imglogo"
                  className="xl:h-10 sm:h-8 h-6"
                />
                <span className="fonts-medium xl:text-[28px] lg:text-2xl sm:text-xl text-base xl:leading-[52px] leading-10">
                  Coming Soon
                </span>
              </div>
              <div className=" flex items-center gap-x-4">
                <img
                  src={imglogo}
                  alt="imglogo"
                  className="xl:h-10 sm:h-8 h-6"
                />
                <span className="fonts-medium xl:text-[28px] lg:text-2xl sm:text-xl text-base xl:leading-[52px] leading-10">
                  Coming Soon
                </span>
              </div>
              <div className=" flex items-center gap-x-4">
                <img
                  src={imglogo}
                  alt="imglogo"
                  className="xl:h-10 sm:h-8 h-6"
                />
                <span className="fonts-medium xl:text-[28px] lg:text-2xl sm:text-xl text-base xl:leading-[52px] leading-10">
                  Coming Soon
                </span>
              </div>
              <div className=" flex items-center gap-x-4">
                <img
                  src={imglogo}
                  alt="imglogo"
                  className="xl:h-10 sm:h-8 h-6"
                />
                <span className="fonts-medium xl:text-[28px] lg:text-2xl sm:text-xl text-base xl:leading-[52px] leading-10">
                  Coming Soon
                </span>
              </div>
              <div className=" flex items-center gap-x-4">
                <img
                  src={imglogo}
                  alt="imglogo"
                  className="xl:h-10 sm:h-8 h-6"
                />
                <span className="fonts-medium xl:text-[28px] lg:text-2xl sm:text-xl text-base xl:leading-[52px] leading-10">
                  Coming Soon
                </span>
              </div>
            </div>
          </Marquee>
        </div>
      </section>
      <section>
        <div className="bg-black text-white sm1:text-center text-start py-5 ">
          <div className="containers">
            <p className="md1:px-20 sm1:px-10 sm:text-lg text-base">
              Coming Soon! Get ready to tap into convenience – the PROMARVEL app
              is about to make home services easier than ever! Stay tuned for
              the launch and let us bring expert care straight to your door.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-[#000]">
          <div
            className="bg-image w-full 4xl:h-[900px] 2xl:h-[800px] xl:h-[750px] md:h-auto h-auto  bg-cover "
            style={{ backgroundImage: `url(${bgimage})` }}
          >
            <div className="containers">
              <div className="grid grid-cols-12 items-center">
                <div className=" lg:col-span-7 md1:col-span-6 col-span-12 md:mt-0 mt-10">
                  <p className="text-white fonts-600 4xl:text-4xl 4xl:w-auto 2xl:w-[750px] xl:text-3xl sm:text-2xl text-xl 4xl:leading-[70px] 2xl:leading-[60px]  xl:leading-[50px] lg:leading-[40px] md:leading-[40px] md1:leading-[40px] sm:leading-[40px] leading-[40px]">
                    Promarvel
                    <br />
                    Where your home shines and beauty thrives—trusted pros just
                    a tap away!
                  </p>

                  <p className="text-[#bababa] fonts-500  4xl:w-[600px] 4xl:text-xl xl:text-lg lg:text-base md:text-base md1:text-sm sm:text-base text-xs 4xl:leading-10 lg:leading-9 md:leading-7 md1:leading-7  sm:leading-8 leading-6 2xl:w-[750px] 4xl:pt-6 xl:pt-4 lg:pt-3 pt-4">
                    Home, beauty, and wellness—expertly handled, effortlessly
                    delivered. Seamless service and style, all in one convenient
                    place.
                  </p>
                  <div className="flex sm:justify-start justify-center">
                    <a
                      onClick={() => {
                        setOpen(true);
                      }}
                      className="text-white flex items-center border lg:text-sm md:text-sm text-xs rounded-[50px] xl:px-9 md:px-7 sm:px-4 px-3 py-3 py-3 4xl:mt-10 2xl:mt-6 lg:mt-5 mt-6"
                    >
                      Download The App
                      <BsArrowUpRightCircleFill className="4xl:text-3xl xl:text-2xl md:text-xl text-xl ml-2" />
                    </a>
                  </div>
                </div>
                <div className=" pl-2  lg:col-span-5 md1:col-span-6 col-span-12 md:place-self-end place-self-center">
                  <img
                    src={phonehand}
                    className="w-auto 4xl:h-[820px] 2xl:h-[720px] xl:h-[670px] lg:h-[532px] md:h-[560px] sm:h-[500px] h-[340px] md1:mt-20 mt-10"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="xl:h-[70px] lg:h-[70px] sm:h-[60px] h-[50px] flex items-center w-full">
          <Marquee>
            <div className="flex items-center lg:gap-x-16 gap-x-14">
              <div className=" flex items-center gap-x-5 lg:ml-16 ml-14">
                <img
                  src={imglogo}
                  alt="imglogo"
                  className=" xl:h-12  sm:h-10 h-8"
                />
                <span className="fonts-600 xl:text-3xl lg:text-2xl sm:text-xl text-lg  xl:leading-[52px] leading-10">
                  Home Appliances
                </span>
              </div>
              <div className=" flex items-center gap-x-5">
                <img
                  src={imglogo}
                  alt="imglogo"
                  className=" xl:h-12 sm:h-10 h-8"
                />
                <span className="fonts-600 xl:text-3xl lg:text-2xl sm:text-xl text-lg xl:leading-[52px] leading-10">
                  Electrician, Carpenter & Pumber
                </span>
              </div>
              <div className=" flex items-center gap-x-5">
                <img
                  src={imglogo}
                  alt="imglogo"
                  className="xl:h-12 sm:h-10 h-8"
                />
                <span className="fonts-600 xl:text-3xl lg:text-2xl sm:text-xl text-lg xl:leading-[52px] leading-10">
                  Cleaning & Pest Control
                </span>
              </div>
              <div className=" flex items-center gap-x-5">
                <img
                  src={imglogo}
                  alt="imglogo"
                  className="xl:h-12 sm:h-10 h-8"
                />
                <span className="fonts-600 xl:text-3xl lg:text-2xl sm:text-xl text-lg xl:leading-[52px] leading-10">
                  Women’s Salon & Spa
                </span>
              </div>
            </div>
          </Marquee>
        </div>
      </section>

      <section>
        <div className="bg-[#1a1a1a]">
          <div className="containers sm1:py-20 py-16">
            <div className="grid grid-cols-12 sm1:gap-x-10">
              <div className="sm1:col-span-6 col-span-12 content-center">
                <p className="text-white fonts-600 2xl:text-4xl  2xl:w-[600px] w-auto xl:text-3xl lg:text-2xl sm1:text-2xl sm:text-3xl text-xl 4xl:leading-[80px] 2xl:leading-[80px] xl:leading-[60px] lg:leading-[50px] sm1:leading-10 sm:leading-[40px] leading-10 ">
                  High-Quality and Friendly Services at Fair Prices
                </p>
                <p className="fonts-500 2xl:text-xl 4xl:w-auto xl:text-lg lg:text-base sm1:text-sm text-xs 4xl:leading-10 xl:leading-10 lg:leading-8 sm1:leading-6 leading-6 text-[#ababab] mt-2">
                  We provide comprehensive services tailored to your needs,
                  including Women’s Salon & Spa.
                </p>
              </div>
              <div className="sm1:col-span-6 col-span-12 sm1:mt-0 mt-10 sm1:place-self-start place-self-center">
                <img
                  src={image1}
                  alt="image1"
                  className=" 4xl:w-auto 4xl:h-auto 2xl:w-[680px] 2xl:h-[575px] lg:h-[400px] md1:h-[300px] sm1:h-[270px] sm:h-[320px] h-72"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-[#0F0F0F]">
          <div className="containers sm1:py-20 py-14">
            <p className="text-white fonts-500  md:text-xl text-lg xl:leading-10 md:leading-8 leading-7 text-center">
              How It works
            </p>
            <p className="text-white fonts-500 md:text-xl text-lg sm1:leading-10 leading-7  text-center md:mt-5 mt-3">
              All Your Home Services in One App!
            </p>
            <p className="sm1:text-center text-start md:mt-5 mt-3 text-[#ababab] fonts-400 4xl:text-xl xl:text-lg md:text-base sm1:text-base text-xs 4xl:leading-10 xl:leading-10 lg:leading-8 sm1:leading-6 leading-6   4xl:px-64 2xl:px-56 xl:px-48 lg:px-36 md:px-28 sm1:px-10 px-0">
              From quick bookings to personalized scheduling and in-store
              services, Promarvel delivers convenience and quality with every
              order.
            </p>
            <div className="grid grid-cols-12 items-center md:mt-0 mt-10 sm1:gap-y-0 gap-y-5">
              <div className="sm1:col-span-6 col-span-12 sm1:order-first order-last">
                <div
                  className="flex justify-center"
                  data-aos="zoom-in"
                  data-aos-duration="1100"
                >
                  <img
                    src={work1}
                    alt="How to Work image1"
                    className="lg:w-[450px] sm1:w-[400px] w-[300px] "
                  />
                </div>
              </div>
              <div className="sm1:col-span-6 col-span-12">
                <div data-aos="zoom-in" data-aos-duration="1100">
                  <div className="flex items-center md1:space-x-4 space-x-2 ">
                    <span className="bg-white text-black rounded-full md:h-12 md:w-12 md1:w-10 md1:h-10 sm1:w-8 sm1:h-8 w-9 h-9 flex items-center justify-center md:text-base text-xs font-semibold border border-4 border-[#ababab]">
                      01
                    </span>
                    <h2 className="md:text-lg text-base fonts-500 text-white">
                      Install the App
                    </h2>
                  </div>
                  <ul className="mt-4 md1:ml-16 ml-9 space-y-4 text-[#c9c9c9]  xl:text-base md1:text-sm sm1:text-xs text-sm">
                    <li className="flex items-start">
                      <img src={rightarrow} alt="right arrow" className="w-5" />
                      <p className="md1:ml-2 ml-1">
                        To begin your journey with Promavel, simply download the
                        Promavel app.
                      </p>
                    </li>
                    <li className="flex items-start">
                      <img src={rightarrow} alt="right arrow" className="w-5" />
                      <p className="ml-2">
                        This app acts as your gateway to easily access all our
                        services in one place.
                      </p>
                    </li>
                    <li className="flex items-start">
                      <img src={rightarrow} alt="right arrow" className="w-5" />
                      <p className="ml-2">
                        With just a few taps, you can explore our wide range of
                        offers, book appointments, manage your account and make
                        secure payments, all at your fingertips from your mobile
                        device.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="md:relative 2xl:-mt-20 md:-mt-12 mt-5">
              <div className="grid grid-cols-12 items-center sm1:mt-0 mt-5 sm1:gap-y-0 gap-y-5">
                <div className="sm1:col-span-6 col-span-12">
                  <div data-aos="zoom-in" data-aos-duration="1100">
                    <div className="flex items-center md1:space-x-4 space-x-2 ">
                      <span className="bg-white text-black rounded-full md:h-12 md:w-12 md1:w-10 md1:h-10 sm1:w-8 sm1:h-8 w-9 h-9 flex items-center justify-center md:text-base text-xs font-semibold border border-4 border-[#ababab]">
                        02
                      </span>
                      <h2 className=" md1:text-lg text-base fonts-500 text-white">
                        Set Up Your Profile
                      </h2>
                    </div>
                    <ul className="mt-4 md1:ml-16 ml-9 space-y-4 text-[#c9c9c9] xl:text-base md:text-sm md1:text-sm sm1:text-xs text-sm">
                      <li className="flex items-start ">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          Quickly create your profile to manage all aspects of
                          your experience.
                        </p>
                      </li>
                      <li className="flex items-start">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          By setting up your account, you will have the ability
                          to easily handle your bookings, make secure payments
                          and access additional features tailored to your needs.
                        </p>
                      </li>
                      <li className="flex items-start">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          Take a moment to set up your profile and unlock the
                          full potential of what Promarvel has to offer.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sm1:col-span-6 col-span-12">
                  <div className="flex justify-center" data-aos="zoom-in">
                    <img
                      src={work2}
                      alt="How to Work image1"
                      className="lg:w-[450px] sm1:w-[400px] w-[300px] "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:relative 2xl:-mt-20 md:-mt-12 mt-5 ">
              <div className="grid grid-cols-12 items-center sm1:mt-0 mt-5 sm1:gap-y-0 gap-y-5">
                <div className="sm1:col-span-6 col-span-12 sm1:order-first order-last">
                  <div
                    className="flex justify-center"
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  >
                    <img
                      src={work3}
                      alt="How to Work image1"
                      className="lg:w-[450px] sm1:w-[400px] w-[300px]"
                    />
                  </div>
                </div>
                <div className="sm1:col-span-6 col-span-12">
                  <div data-aos="zoom-in" data-aos-duration="2000">
                    <div className="flex items-center md1:space-x-4 space-x-2 ">
                      <span className="bg-white text-black rounded-full md:h-12 md:w-12 md1:w-10 md1:h-10 sm1:w-8 sm1:h-8 w-9 h-9 flex items-center justify-center  md:text-base text-xs  font-semibold border border-4 border-[#ababab]">
                        03
                      </span>
                      <h2 className=" md1:text-lg text-base fonts-500 text-white">
                        Rapid Booking
                      </h2>
                    </div>
                    <ul className="mt-4 md1:ml-16 ml-9 space-y-4 text-[#c9c9c9]  xl:text-base md1:text-sm sm1:text-xs text-sm">
                      <li className="flex items-start ">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          Need service fast? Connect with a nearby service
                          expert for quick assistance, usually within a few
                          hours.
                        </p>
                      </li>
                      <li className="flex items-start">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          Whether it's an urgent or last-minute request, our
                          professionals are available to provide fast, reliable
                          help—usually in just a few hours.
                        </p>
                      </li>
                      <li className="flex items-start">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          Experience convenience and efficiency at your
                          fingertips.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:relative 2xl:-mt-20 md:-mt-12 mt-5">
              <div className="grid grid-cols-12 items-center sm1:mt-0 mt-5 sm1:gap-y-0 gap-y-5">
                <div className="sm1:col-span-6 col-span-12">
                  <div data-aos="zoom-in" data-aos-duration="2000">
                    <div className="flex items-center md1:space-x-4 space-x-2 ">
                      <span className="bg-white text-black rounded-full md:h-12 md:w-12 md1:w-10 md1:h-10 sm1:w-8 sm1:h-8 w-9 h-9 flex items-center justify-center  md:text-base text-xs  font-semibold border border-4 border-[#ababab]">
                        04
                      </span>
                      <h2 className="md1:text-lg text-base fonts-500 text-white">
                        Future Booking
                      </h2>
                    </div>
                    <ul className="mt-4 md1:ml-16 ml-9 space-y-4 text-[#c9c9c9]  xl:text-base md1:text-sm sm1:text-xs text-sm">
                      <li className="flex items-start ">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          If you find yourself in need of a quick service, don't
                          worry! You can easily connect with the nearest service
                          expert who is ready to help you.
                        </p>
                      </li>
                      <li className="flex items-start">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          Our professionals are dedicated to providing fast
                          support, ensuring that help is usually available
                          within just a few hours
                        </p>
                      </li>
                      <li className="flex items-start">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          Whether it's an urgent repair or a last-minute
                          request, we're here to ensure you get the support you
                          need without delay.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sm1:col-span-6 col-span-12">
                  <div
                    className="flex justify-center"
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  >
                    <img
                      src={work4}
                      alt="How to Work image1"
                      className="lg:w-[450px] sm1:w-[400px] w-[300px]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:relative 2xl:-mt-20 md:-mt-12 mt-5">
              <div className="grid grid-cols-12 items-center sm1:mt-0 mt-5 sm1:gap-y-0 gap-y-5">
                <div className="sm1:col-span-6 col-span-12 sm1:order-first order-last">
                  <div
                    className="flex justify-center"
                    data-aos="zoom-in"
                    data-aos-duration="3000"
                  >
                    <img
                      src={work5}
                      alt="How to Work image1"
                      className="lg:w-[450px] sm1:w-[400px] w-[300px]"
                    />
                  </div>
                </div>
                <div className="sm1:col-span-6 col-span-12">
                  <div data-aos="zoom-in" data-aos-duration="3000">
                    <div className="flex items-center md1:space-x-4 space-x-2 ">
                      <span className="bg-white text-black rounded-full md:h-12 md:w-12 md1:w-10 md1:h-10 sm1:w-8 sm1:h-8 w-9 h-9 flex items-center justify-center  md:text-base text-xs   font-semibold border border-4 border-[#ababab]">
                        05
                      </span>
                      <h2 className=" md1:text-lg text-base fonts-500 text-white">
                        Promarvel Store
                      </h2>
                    </div>
                    <ul className="mt-4 md1:ml-16 ml-9 space-y-4 text-[#c9c9c9] xl:text-base md:text-base md1:text-sm sm1:text-xs text-sm">
                      <li className="flex items-start ">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          Visit a ProMarvel store to experience our exclusive
                          salon services.
                        </p>
                      </li>
                      <li className="flex items-start">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          Get personalized assistance from our expert team,
                          whether you need advice or direct support. We’re here
                          to guide you every step of the way.
                        </p>
                      </li>
                      <li className="flex items-start">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          Discover the quality and care ProMarvel is known for!
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:relative 2xl:-mt-20 md:-mt-12 mt-5">
              <div className="grid grid-cols-12 items-center sm1:mt-0 mt-5 sm1:gap-y-0 gap-y-5">
                <div className="sm1:col-span-6 col-span-12">
                  <div data-aos="zoom-in" data-aos-duration="3000">
                    <div className="flex items-center md1:space-x-4 space-x-2 ">
                      <span className="bg-white text-black rounded-full md:h-12 md:w-12 md1:w-10 md1:h-10 sm1:w-8 sm1:h-8 w-9 h-9 flex items-center justify-center  md:text-base text-xs  font-semibold border border-4 border-[#ababab]">
                        06
                      </span>
                      <h2 className=" md1:text-lg text-base fonts-500 text-white">
                        Book and enjoy the features
                      </h2>
                    </div>
                    <ul className="mt-4 md1:ml-16 ml-9 space-y-4 text-[#c9c9c9]  xl:text-base md1:text-sm sm1:text-xs text-sm">
                      <li className="flex items-start ">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          With Promarvel, you can securely book services and
                          experience the convenience of seamless scheduling at
                          your fingertips.
                        </p>
                      </li>
                      <li className="flex items-start">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          You get reliable service from trusted professionals,
                          plus handling transactions is easier than ever with
                          our flexible and secure payment options.
                        </p>
                      </li>
                      <li className="flex items-start">
                        <img
                          src={rightarrow}
                          alt="right arrow"
                          className="w-5"
                        />
                        <p className="ml-2">
                          Enjoy the peace of mind knowing your booking is secure
                          and focus on getting the high-quality service you
                          deserve.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sm1:col-span-6 col-span-12">
                  <div
                    className="flex justify-center"
                    data-aos="zoom-in"
                    data-aos-duration="3000"
                  >
                    <img
                      src={work6}
                      alt="How to Work image1"
                      className="lg:w-[450px] sm1:w-[400px] w-[300px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-[#1a1a1a] ">
          <div className="containers sm1:py-20 py-14">
            <div className="grid grid-cols-12 md1:gap-x-10 gap-x-4 xl:mb-40  sm1:mb-32 mb-20">
              <div className="sm1:col-span-7 col-span-12 sm1:place-self-start place-self-center">
                <img
                  src={downloadapp1}
                  alt="download image"
                  className="4xl:h-[750px] 2xl:h-[650px] xl:h-[550px] lg:h-[455px] md1:h-[350px] sm1:h-[300px] sm:h-96 xs:h-72 h-64 "
                />
              </div>
              <div className="sm1:col-span-5 col-span-12">
                <div className="2xl:mt-24 lg:mt-14 sm1:mt-10 mt-6">
                  <p className="fonts-600 2xl:text-4xl xl:text-3xl lg:text-2xl sm1:text-xl text-lg 4xl:leading-[80px] 2xl:leading-[60px] xl:leading-[60px] lg:leading-[50px] md:leading-[40px] leading-10 text-white">
                    Download The User App
                  </p>
                  <p className="fonts-500  2xl:text-xl lg:text-lg md1:text-sm sm1:text-xs text-sm 2xl:leading-[42px] lg:leading-8 md:leading-7  sm1:leading-5 sm:leading-7 leading-7 text-[#ababab] 4xl:mt-10 2xl:mt-7 md1:mt-6 mt-3 4xl:mb-16 lg:mb-10 md1:mb-8 mb-4 ">
                    Download the Promarvel app for convenient access to a wide
                    range of home services. Effortlessly book appointments,
                    manage your account, and make secure payments—all from your
                    mobile device. Streamline your life with just a few taps!
                  </p>
                  <a
                    className="text-white fonts-600 4xl:text-2xl 2xl:text-xl lg:text-lg md1:text-base sm1:text-sm text-base leading-10 underline decoration-2 cursor-pointer flex sm1:justify-start justify-center "
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Download App Now
                  </a>
                  <div className="flex sm1:justify-start justify-center md1:gap-x-5 gap-x-3 4xl:mt-8 2xl:mt-6 md1:mt-5 mt-2">
                    <img
                      src={appstoreimg}
                      alt="app store image"
                      className="4xl:w-52 4xl:h-16 2xl:w-48 2xl:h-14 xl:w-38 xl:h-12 lg:w-32 lg:h-10 md1:w-26 md1:h-9 sm1:w-24 sm1:h-8 w-26 h-9"
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                    <img
                      src={gplayimg}
                      alt="google play image"
                      className="4xl:w-52 4xl:h-16 2xl:w-48 2xl:h-14 xl:w-38 xl:h-12 lg:w-32 lg:h-10 md1:w-26 md1:h-9 sm1:w-24 sm1:h-8 w-26 h-9"
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 md1:gap-x-10 gap-x-4 items-center">
              <div className="sm1:col-span-5 col-span-12  sm1:order-first order-last">
                <div className="">
                  <p className="fonts-600 2xl:text-4xl xl:text-3xl lg:text-2xl sm1:text-xl text-lg sm1:mt-0 mt-6 4xl:leading-[60px] 2xl:leading-[60px] xl:leading-[60px] lg:leading-[50px] md:leading-[40px] leading-10 text-white">
                    Download The Service Expert App
                  </p>
                  <p className="fonts-500  2xl:text-xl lg:text-lg md1:text-sm sm1:text-xs text-sm 2xl:leading-[42px] lg:leading-8 md:leading-7 sm1:leading-5 sm:leading-7 leading-7 text-[#ababab] 4xl:mt-7 2xl:mt-7 md1:mt-6 mt-3 4xl:mb-16 lg:mb-10 md1:mb-8 mb-4 ">
                    Sign up for our service provider app to unlock new
                    opportunities and connect with clients easily. Manage your
                    appointments, track your earnings, and receive secure
                    payments—all from your mobile device. Elevate your services
                    and expand your reach with just a few taps!
                  </p>
                  <a
                    onClick={() => {
                      setOpen(true);
                    }}
                    className="text-white fonts-600 4xl:text-2xl 2xl:text-xl lg:text-lg md1:text-base sm1:text-sm text-base leading-10 underline decoration-2 cursor-pointer flex sm1:justify-start justify-center "
                  >
                    Download App Now
                  </a>
                  <div className="flex sm1:justify-start justify-center md1:gap-x-5 gap-x-3 4xl:mt-8 2xl:mt-6 md1:mt-5 mt-2">
                    <img
                      src={appstoreimg}
                      alt="app store image"
                      className="4xl:w-52 4xl:h-16 2xl:w-48 2xl:h-14 xl:w-38 xl:h-12 lg:w-32 lg:h-10 md1:w-26 md1:h-9 sm1:w-24 sm1:h-8 w-26 h-9"
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                    <img
                      src={gplayimg}
                      alt="google play image"
                      className="4xl:w-52 4xl:h-16 2xl:w-48 2xl:h-14 xl:w-38 xl:h-12 lg:w-32 lg:h-10 md1:w-26 md1:h-9 sm1:w-24 sm1:h-8 w-26 h-9"
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="sm1:col-span-7 col-span-12 sm1:place-self-start place-self-center ">
                <img
                  src={downloadapp2}
                  alt="download image"
                  className="4xl:h-[750px] 2xl:h-[650px] xl:h-[550px] lg:h-[455px] md1:h-[350px] sm1:h-[300px] sm:h-96 xs:h-72 h-64 "
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-[#0F0F0F]">
          <div className="containers sm1:py-20 py-14">
            <h2 className="text-white fonts-500 2xl:text-4xl xl:text-3xl lg:text-2xl sm1:text-xl text-xl xl:leading-[67px] leading-10 text-center">
              Stay Updated with Our Tips & Services!
            </h2>
            <div className="h-px w-full bg-[#171717] md:my-12 sm:my-10 my-8"></div>
            <div className="md1:grid grid-cols-12 2xl:gap-x-10 lg:gap-x-8 sm1:gap-x-7 sm1:gap-y-0 gap-y-10 hidden">
              <div className="sm1:col-span-4 col-span-12 sm1:place-self-start place-self-center ">
                <div className="card bg-[#141414] hover:bg-[#262626] w-full rounded-[30px] 2xl:h-[645px] xl:h-[570px] lg:h-[505px] md:h-[425px] sm1:w-auto sm1:h-[430px] sm:w-96 ">
                  <img
                    src={card1}
                    alt="card"
                    className=" 4xl:w-full 4xl:h-[380px] sm1:w-full rounded-t-[30px]"
                  />
                  <div className="pt-6 pb-8">
                    <h3 className=" 2xl:px-12 xl:px-10 lg:px-2 md:px-5  xs:px-7 px-3 text-white fonts-500 2xl:text-2xl xl:leading-8 xl:text-xl lg:text-lg md:text-base sm1:text-sm text-base text-center xl:pb-4 lg:pb-2 md:pb-5 pb-3">
                      How to Use Beauty Products on Your Face
                    </h3>
                    <p className=" 2xl:px-8 lg:px-7 px-4 fonts-400 2xl:text-lg xl:text-base lg:text-sm md:text-xs sm1:text-xs text-sm text-center xl:leading-7 lg:leading-6 leading-5 text-[#c9c9c9]">
                      Using beauty products on your face effectively can
                      transform your skincare and makeup routine. Start by
                      cleansing to remove impurities and prep your skin
                    </p>
                  </div>
                </div>
              </div>
              <div className="sm1:col-span-4 col-span-12 sm1:place-self-start place-self-center ">
                <div className="card bg-[#141414] hover:bg-[#262626] w-full rounded-[30px] 2xl:h-[645px] xl:h-[570px] lg:h-[505px] md:h-[425px] sm1:w-auto sm1:h-[430px] sm:w-96">
                  <img
                    src={card2}
                    alt="card"
                    className=" 4xl:w-full 4xl:h-[380px] rounded-t-[30px]"
                  />
                  <div className="pt-6 pb-8">
                    <h3 className=" 2xl:px-12 xl:px-10 lg:px-2 md:px-5 xs:px-7 px-3 text-white fonts-500 2xl:text-2xl xl:leading-8 xl:text-xl lg:text-lg md:text-base sm1:text-sm text-base text-center xl:pb-4 lg:pb-2 md:pb-5 pb-3">
                      Points to Consider for Electrical Systems
                    </h3>
                    <p className=" 2xl:px-8 lg:px-7 px-4 fonts-400 2xl:text-lg xl:text-base lg:text-sm md:text-xs sm1:text-xs text-sm text-center xl:leading-7 lg:leading-6 leading-5 text-[#c9c9c9] ">
                      When evaluating electrical systems, there are several key
                      points to consider. First, ensure that all wiring is up to
                      code and properly insulated to prevent electrical hazards
                    </p>
                  </div>
                </div>
              </div>
              <div className="sm1:col-span-4 col-span-12 sm1:place-self-start place-self-center ">
                <div className="card bg-[#141414] hover:bg-[#262626] w-full rounded-[30px] 2xl:h-[645px] xl:h-[570px] lg:h-[505px] md:h-[425px] sm1:w-auto sm1:h-[430px] sm:w-96">
                  <img
                    src={card3}
                    alt="card"
                    className=" 4xl:w-full 4xl:h-[380px] rounded-t-[30px]"
                  />
                  <div className="pt-6 pb-8">
                    <h3 className=" 2xl:px-12 xl:px-10 lg:px-2 md:px-5 sm1:px-3 xs:px-7 px-3 text-white fonts-500 2xl:text-2xl xl:leading-8 xl:text-xl lg:text-lg md:text-base sm1:text-sm text-base text-center xl:pb-4 lg:pb-2 md:pb-5 pb-3">
                      The Benefits of Regular Professional Cleaning
                    </h3>
                    <p className=" 2xl:px-8 lg:px-7 px-4 fonts-400 2xl:text-lg xl:text-base lg:text-sm md:text-xs  sm1:text-xs text-sm text-center xl:leading-7 lg:leading-6 leading-5 text-[#c9c9c9]">
                      Understand the numerous advantages of scheduling regular
                      professional cleanings. From improving indoor air...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="md1:hidden ">
              <Slider {...settings}>
                <div>
                  <div className="sm1:col-span-4 col-span-12 sm1:place-self-start place-self-center sm1:mx-5  mx-5">
                    <div className="card bg-[#141414] hover:bg-[#262626] w-full rounded-[30px] 2xl:h-[636px] xl:h-[560px] lg:h-[505px] md:h-[425px] sm1:w-auto sm1:h-[430px] xs:h-[500px] h-[450px] sm:w-96 ">
                      <img
                        src={card1}
                        alt="card"
                        className=" 4xl:w-full 4xl:h-[380px] sm1:w-full rounded-t-[30px] "
                      />
                      <div className="pt-6 pb-8">
                        <h3 className=" 2xl:px-12 lg:px-10 md:px-5 xs:px-7 px-3 text-white fonts-500 2xl:text-2xl xl:leading-8 xl:text-xl lg:text-lg md:text-base sm1:text-sm text-base text-center xl:pb-6 md:pb-5 pb-3">
                          How to Use Beauty Products on Your Face
                        </h3>
                        <p className=" 2xl:px-8 lg:px-7 xs:px-7 px-4 fonts-400 2xl:text-lg xl:text-base lg:text-sm md:text-xs sm1:text-xs text-sm sm1:text-center text-start xl:leading-7 lg:leading-6 leading-5 text-[#c9c9c9]">
                          Using beauty products on your face effectively can
                          transform your skincare and makeup routine. Start by
                          cleansing to remove impurities and prep your skin
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="sm1:col-span-4 col-span-12 sm1:place-self-start place-self-center sm1:mx-5 mx-5">
                    <div className="card bg-[#141414] hover:bg-[#262626] w-full rounded-[30px] 2xl:h-[636px] xl:h-[560px] lg:h-[505px] md:h-[425px] sm1:w-auto sm1:h-[430px] xs:h-[500px] h-[450px] sm:w-96">
                      <img
                        src={card2}
                        alt="card"
                        className=" 4xl:w-full 4xl:h-[380px] rounded-t-[30px]"
                      />
                      <div className="pt-6 pb-8">
                        <h3 className=" 2xl:px-12 lg:px-10 md:px-5 xs:px-7 px-3 text-white fonts-500 2xl:text-2xl xl:leading-8 xl:text-xl lg:text-lg md:text-base sm1:text-sm text-base text-center xl:pb-6 md:pb-5 pb-3">
                          Points to Consider for Electrical Systems
                        </h3>
                        <p className=" 2xl:px-8 lg:px-7 xs:px-7 px-4  fonts-400 2xl:text-lg xl:text-base lg:text-sm md:text-xs sm1:text-xs text-sm sm1:text-center text-start xl:leading-7 lg:leading-6 leading-5 text-[#c9c9c9] ">
                          When evaluating electrical systems, there are several
                          key points to consider. First, ensure that all wiring
                          is up to code and properly insulated to prevent
                          electrical hazards
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="sm1:col-span-4 col-span-12 sm1:place-self-start place-self-center sm1:mx-5  mx-5">
                    <div className="card bg-[#141414] hover:bg-[#262626] w-full rounded-[30px] 2xl:h-[636px] xl:h-[560px] lg:h-[505px] md:h-[425px] sm1:w-auto sm1:h-[430px] xs:h-[500px] h-[450px] sm:w-96">
                      <img
                        src={card3}
                        alt="card"
                        className=" 4xl:w-full 4xl:h-[380px] rounded-t-[30px]"
                      />
                      <div className="pt-6 pb-8">
                        <h3 className=" 2xl:px-12 lg:px-10 md:px-5 sm1:px-3 xs:px-7 px-3 text-white fonts-500 2xl:text-2xl xl:leading-8 xl:text-xl lg:text-lg md:text-base sm1:text-sm text-base text-center  xl:pb-6 md:pb-5 pb-3">
                          The Benefits of Regular Professional Cleaning
                        </h3>
                        <p className=" 2xl:px-8 lg:px-7 xs:px-7 px-4  fonts-400 2xl:text-lg xl:text-base lg:text-sm md:text-xs  sm1:text-xs text-sm sm1:text-center text-start xl:leading-7 lg:leading-6 leading-5 text-[#c9c9c9]">
                          Understand the numerous advantages of scheduling
                          regular professional cleanings. From improving indoor
                          air...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section id="contact">
        <div className="grid grid-cols-12">
          <div className="md1:col-span-6 col-span-12  bg-[#1a1a1a] 4xl:pl-[260px] 2xl:pl-[260px] xl:pl-[200px] lg:pl-[190px] md:pl-[150px] md1:pl-[100px] sm1:px-[80px] px-[30px] ">
            <div className="grid gap-y-10 rounded-[30px]  xl:pr-20 lg:pr-8 md:pr-0 md1:px-0 sm:px-10  xl:py-16 md:py-14 py-12 ">
              <h2 className=" text-white fonts-500 4xl:text-4xl xl:text-3xl sm1:text-2xl text-xl xl:leading-[67px] leading-10   ">
                Contact Us
              </h2>
              <div className=" h-fit xl:p-4 p-3 rounded-3xl border border-[#A5A5A5] w-auto ">
                <div className="flex gap-x-3 items-center">
                  <div className="bg-[#3d3d3d] rounded-full xl:w-[70px] xl:h-[70px] lg:w-[60px] lg:h-[60px] md:w-14 md:h-14 sm1:w-12 sm1:h-12 sm:w-14 sm:h-14 w-12 h-12 ">
                    <img
                      src={call}
                      alt="call icon"
                      className="xl:w-8 xl:h-8 lg:w-7 lg:h-7 md:w-6 md:h-6 sm1:w-5 sm1:h-5 sm:w-6 sm:h-6 w-5 h-5 mx-auto xl:mt-5 mt-4"
                    />
                  </div>
                  <div className="text-[#c9c9c9] fonts-500 grid gap-y-3">
                    <p className="4xl:text-xl xl:text-lg text-sm">Call Us</p>
                    <p className="4xl:text-base xl:text-sm text-xs">
                      (+91) 8886006622
                    </p>
                  </div>
                </div>
              </div>
              <div className=" h-fit xl:p-4 p-3 rounded-3xl border border-[#A5A5A5] w-auto ">
                <div className="flex gap-x-3 items-center">
                  <div className="bg-[#3d3d3d] rounded-full xl:w-[70px] xl:h-[70px] lg:w-[60px] lg:h-[60px] md:w-14 md:h-14 sm1:w-12 sm1:h-12 sm:w-14 sm:h-14 w-12 h-12">
                    <img
                      src={sms}
                      alt="sms icon"
                      className="xl:w-8 xl:h-8 lg:w-7 lg:h-7 md:w-6 md:h-6 sm1:w-5 sm1:h-5 sm:w-6 sm:h-6 w-5 h-5 mx-auto xl:mt-5 mt-4"
                    />
                  </div>
                  <div className="text-[#c9c9c9] fonts-500 grid gap-y-3">
                    <p className="4xl:text-xl xl:text-lg text-sm">Email</p>
                    <p className="4xl:text-base xl:text-sm text-xs">
                      <a
                        href="contact@promarvel.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        ref={linkRef}
                        className="underline"
                      >
                        contact@promarvel.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="h-fit xl:p-4 p-3 rounded-3xl border border-[#A5A5A5] w-auto ">
                <div className="flex gap-x-3 items-center">
                  <div className="bg-[#3d3d3d] rounded-full xl:w-[70px] xl:h-[70px] lg:w-[60px] lg:h-[60px] md:w-14 md:h-14 sm1:w-12 sm1:h-12 sm:w-14 sm:h-14 w-12 h-12">
                    <img
                      src={location}
                      alt="location icon"
                      className="xl:w-8 xl:h-8 lg:w-7 lg:h-7 md:w-6 md:h-6 sm1:w-5 sm1:h-5 sm:w-6 sm:h-6 w-5 h-5 mx-auto xl:mt-5 mt-4"
                    />
                  </div>
                  <div className="text-[#c9c9c9] fonts-500 grid gap-y-3">
                    <p className="4xl:text-xl xl:text-lg text-sm"> Address</p>
                    <p className="4xl:text-base xl:text-sm text-xs">
                      Hyderabad, Telangana
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md1:col-span-6 col-span-12  place-content-center bg-[#262626]  4xl:pr-[260px] 2xl:pr-[260px] xl:pr-[200px] lg:pr-[190px] md:pr-[150px] md1:pr-[100px] sm1:px-[70px] px-[30px] ">
            <div className="form-title rounded-[30px] xl:pl-20 lg:pl-8 md:pl-0 md1:px-0 sm:px-10 xl:py-16 md:py-14 py-12">
              <h2 className="text-white fonts-500 4xl:text-2xl xl:text-xl text-xl leading-10">
                Get In Touch
              </h2>
              <p className="text-white 4xl:text-xl xl:text-xl md:text-base text-sm md:leading-8 leading-7 fonts-500 mt-5 mb-8">
                Please fill out the quick form, and we will get in touch with
                you right away.
              </p>
              <div className="form">
                <form className="space-y-6" onSubmit={formik.handleSubmit}>
                  <div>
                    <input
                      id="fname"
                      type="text"
                      name="fname"
                      placeholder="Enter Your Full Name"
                      className="w-full px-4 lg:py-[18px] md:py-3.5 py-3  2xl:text-base text-sm bg-[#3d3d3d] text-white border border-[#A5A5A5] rounded-xl focus:outline-none"
                      value={formik.values.fname}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.fname && formik.touched.fname && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.fname}
                      </p>
                    )}
                  </div>
                  <div>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Enter your Email ID"
                      className="w-full px-4 lg:py-[18px]  md:py-3.5 py-3 2xl:text-base text-sm bg-[#3d3d3d] text-white border border-[#A5A5A5] rounded-xl focus:outline-none "
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.email}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="flex justify-start">
                      <PhoneInput
                        country="in"
                        value={phoneData.number}
                        onChange={(phone, countryData) =>
                          handlePhoneChange(phone, countryData)
                        }
                        required
                        className="w-full 2xl:text-base text-sm focus:outline-none "
                      />
                    </div>
                    {formik.errors.phoneno && formik.touched.phoneno && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.phoneno}
                      </p>
                    )}
                  </div>
                  <div>
                    <textarea
                      id="message"
                      name="message"
                      placeholder="Enter Message"
                      className="w-full md:px-4 px-2 lg:py-[18px] md:py-3.5 py-3 2xl:text-base text-sm text-white bg-[#3d3d3d] border border-[#A5A5A5] rounded-xl focus:outline-none "
                      rows="4"
                      value={formik.values.message}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    ></textarea>

                    {formik.errors.message && formik.touched.message && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.message}
                      </p>
                    )}
                  </div>
                  <div className="flex justify-center items-center">
                    <button
                      type="submit"
                      className="w-auto py-2.5 4xl:px-28 2xl:px-24 xl:px-18 lg:px-10 md:px-10 px-8 sm1:text-base text-xs font-semibold text-black rounded-xl focus:outline-none bg-white fonts-500   "
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Dialog
        open={Open}
        onClose={() => setOpen(false)}
        className="relative z-50"
      >
        <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-[2px]" />
        <div className="fixed inset-0 flex w-screen items-center justify-center  p-4">
          <DialogPanel className="max-w-2xl space-y-3 rounded-xl bg-[#1E1E1E] text-white py-7 px-7 ">
            <DialogTitle className="font-bold text-center text-base text-[#c9c9c9] fonts-600">
              Almost there!{" "}
            </DialogTitle>
            <Description className="text-sm text-center text-[#c9c9c9] mt-1 fonts-300">
              "The PROMARVEL app is launching soon. Stay tuned for the big
              reveal and be among the first to enjoy hassle-free home services
              at your fingertips!"
            </Description>
            <div className="flex justify-center">
              <button
                className="px-16 py-2 bg-white text-[#121212] text-base rounded-xl fonts-500  mt-4"
                onClick={() => setOpen(false)}
              >
                Okay
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
export default Homepage;
